import { lazy, Fragment } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";
import { EndFirstStep, EndAllStep } from "../../components/paymentez/utils";
import Navbar from "../../layouts/utils/Navbar";

const routeBusinessGateway = (props) => [
  { element: <Navigate to="/app/mis-afiliaciones" replace /> },

  {
    path: "panel-seguimiento/:affiliation",
    element: <MonitoringPanel key={props.pathname.split("/")[4]} />,
  },
  {
    path: "detalle-documentacion/:affiliation",
    element: <MonitoringStatusDetail key={props.pathname.split("/")[4]} />,
  },
  {
    path: "datos-establecimiento/:affiliation",
    element: <EstablishmentData key={props.pathname.split("/")[4]} />,
  },
  {
    path: "documentos-habilitantes/:affiliation",
    element: <EnablingDocuments key={props.pathname.split("/")[4]} />,
  },
  {
    path: "documentos-nuvei/:affiliation",
    element: (
      <Fragment>
        {props?.gateway?.showViewEndFistStep ? (
          <>
            <Navbar />
            <EndFirstStep />{" "}
          </>
        ) : (
          <PaymentezDocuments key={props.pathname.split("/")[4]} />
        )}
      </Fragment>
    ),
  },
  {
    path: "documentos-nuvei/:affiliation/:form",
    element: (
      <Fragment>
        {props?.gateway?.showViewEndFistStep ? (
          <>
            <Navbar />
            <EndFirstStep />{" "}
          </>
        ) : (
          <PaymentezDocuments key={props.pathname.split("/")[4]} />
        )}
      </Fragment>
    ),
  },
  {
    path: "documentos-bancos/:affiliation",
    element: <BanksDocuments key={props.pathname.split("/")[4]} />,
  },
  {
    path: "documentos-bancos/:affiliation/:bank/:form",
    element: <BanksDocuments key={props.pathname.split("/")[4]} />,
    },
    {
        path: 'documentacion-adicional-bancos/:affiliation', element:

            <Fragment>
                {props.gateway?.showViewEndLastStep
                    ?
                    <><Navbar /><EndAllStep /> </>
                    :
                    <AdditionalDocumentation />
                }

            </Fragment>
    },
  {
    path: "resume/:affiliation",
    element: <ResumeAffiliation key={props.pathname.split("/")[4]} />,
  },

  {
    path: "notificaciones/:affiliation",
    element: <Notification key={props.pathname.split("/")[4]} />,
  },
  {
    path: "notificaciones/:affiliation/:id",
    element: (
      <Notification key={props.pathname.split("/")[4] + Math.random()} />
    ),
  },
];

const EnablingDocuments = Loadable(
  lazy(() => import("../../pages/EnablingDocuments"))
);
const EstablishmentData = Loadable(
  lazy(() => import("../../pages/EstablishmentData"))
);
const PaymentezDocuments = Loadable(
  lazy(() => import("../../pages/PaymentezDocuments"))
);
const BanksDocuments = Loadable(
  lazy(() => import("../../pages/BanksDocuments"))
);

const MonitoringPanel = Loadable(
  lazy(() => import("../../pages/MonitoringPanel"))
);
const MonitoringStatusDetail = Loadable(
  lazy(() => import("../../pages/MonitoringStatusDetail"))
);
const ResumeAffiliation = Loadable(
  lazy(() => import("../../pages/ResumeAffiliation"))
);

const AdditionalDocumentation = Loadable(
  lazy(() => import("../../pages/AdditionalDocumentation"))
);
const Notification = Loadable(lazy(() => import("../../pages/Notifications")));

export { Loadable };

export default routeBusinessGateway;
