import { Navigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { PATH_PAYMENTEZ } from "../routes/paths";
import { Outlet } from 'react-router-dom';

StepsGuard.propTypes = {
    children: PropTypes.node
};

const Step1IsDisabled = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true
}

const Step2IsDisabled = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true;
}

const Step3IsDisabled = (status) => {

    if (status === 1 || status > 3) {

        return false;
    }
    return true;
}



export default function StepsGuard() {
    const { businessData } = useAuth();
    const params = useParams();
    const { pathname } = useLocation();


    const getAffiliationInfo = businessData?.affiliationData.find(e => e.affiliationDataId === params.affiliation && e.typeAffiliation === 1);
   
    if (getAffiliationInfo !== undefined) {

        var statusAffiliation = getAffiliationInfo.statusAffiliate;

        var step1Status = getAffiliationInfo.stepStatus?.find(e => e.step === 1)?.status;
        var step2Status = getAffiliationInfo.stepStatus?.find(e => e.step === 2)?.status;
        var step3Status = getAffiliationInfo.stepStatus?.find(e => e.step === 3)?.status;

        let urlEstablishmentData = PATH_PAYMENTEZ.app.gateway.establishmentData + params.affiliation;
        let urlEnablingDocuments = PATH_PAYMENTEZ.app.gateway.enablingDocuments + params.affiliation;
        let urlPmzDocuments = PATH_PAYMENTEZ.app.gateway.paymentezDocuments + params.affiliation;
        let urlBanksDocuments = PATH_PAYMENTEZ.app.gateway.banksDocuments + params.affiliation;
        let urlPanel = PATH_PAYMENTEZ.app.gateway.monitoringPanel + params.affiliation;
        let urlStateDocument = PATH_PAYMENTEZ.app.gateway.monitoringStateDocument + params.affiliation;
        let urlResume = PATH_PAYMENTEZ.app.gateway.resume + params.affiliation;


        if (pathname === urlEstablishmentData && statusAffiliation === 7) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }


        if (pathname === urlEnablingDocuments && Step1IsDisabled(step1Status) && statusAffiliation < 7) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

        if (pathname === urlPmzDocuments && (Step1IsDisabled(step1Status) || Step2IsDisabled(step2Status)) && statusAffiliation < 7) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

        if (pathname === urlBanksDocuments && (Step1IsDisabled(step1Status) || Step2IsDisabled(step2Status) || Step3IsDisabled(step3Status)) && statusAffiliation < 7) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }


        if (pathname === urlPanel && statusAffiliation === 7) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

        if (pathname === urlStateDocument && statusAffiliation === 7) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

        if (pathname === urlResume && statusAffiliation < 7) {
            return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;
        }

    } else {

        return <Navigate to={PATH_PAYMENTEZ.app.pageAllAffiliations} />;

    }


    return <Outlet />;
}
