import { Fragment } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
    Grid, Dialog, DialogContent, DialogContentText, DialogActions, Divider, DialogTitle, Typography, Alert, Link
} from "@mui/material";

import { styled } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import onboard from '../../../assets/images/icons/onboard.png';
import { PATH_PAYMENTEZ } from "../../../routes/paths";

const ModalStyle = styled('div')(({ theme }) => ({



}));

const css = `
    [role="dialog"] {
        z-index:9999;
    }
    [role="tooltip"]{
     z-index:9999;
    }
 .SnackbarContainer-top {
    z-index:99990 !important;
 }

`



export default function ModalInformation(props) {

    const params = useParams();

    return (


        <Fragment>
            <ModalStyle>
                <style>{css}</style>
                <Dialog open={alert} onClose={() => { props?.handleClose(props?.type) }} maxWidth="md" >
                    
                    {/*<DialogTitle sx={{ padding: '26px' }}>Ten en cuenta esta información si deseas afiliarte a estos bancos:</DialogTitle>*/}
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" sx={{ color: "#212B36" }}>
                                {/* Puedes retomar el proceso de afiliación cuando tengas más tiempo. No olvides continuar lo más pronto posible.*/}
                            </DialogContentText>

                        <Grid container>

                            <Grid item xs={12} md={3}>


                                <img src={onboard} style={{ padding: '15%' }}  />
                            </Grid>

                            <Grid item xs={12} md={9}>

                               
                                    <Typography variant="h4" style={{ width:'100%' }} color="#E40046">Importante</Typography>
                                    <Divider />
                              {/*  <Typography sx={{marginTop:'10px',marginBottom:'5px'}}><strong>Guayaquil:</strong>  Es de obligatoriedad remitir a su asesor comercial el catálogo de productos o servicios en formato PDF, que incluya los precios establecidos.</Typography>*/}
                                <Typography sx={{ marginTop:'13px',marginBottom: '5px' }}><strong>Solidario:</strong> Debes imprimir los contratos y solicitudes de lado y lado, firmarlas y llevarlas al banco.</Typography>
                                
                                <Typography sx={{ marginBottom: '10px' }}><strong>Produbanco:</strong> Debes imprimir los contratos y solicitudes de lado y lado, firmarlas y llevarlas al banco. Además, es obligatorio tener cuenta en dicha institución financiera y patente municipal.</Typography>
                                
                                <Typography sx={{ marginBottom: '10px' }}><strong>Bolivariano:</strong> Es obligatorio tener cuenta en dicha institución financiera.
                                    <br /> Debes entregar toda la documentación impresa, firmarlas y llevarlas al banco.
                                    </Typography>
                                <Typography sx={{ marginBottom: '10px' }}><strong>General:</strong>  Es fundamental enviar a su asesor comercial el estado de cuenta de la cuenta designada como forma de pago. Este documento adicional es requerido por todas las entidades bancarias.</Typography>

                                    {props?.finalStep === true &&

                                        <Alert severity="info">
                                            Importante: <br />
                                            Luego de este paso no podrás elegir más bancos para afiliarte. Lo podrás hacer luego de la firma de documentos.
                                            <br />

                                        <Link to={PATH_PAYMENTEZ.app.gateway.banksDocuments + params.affiliation} variant="text" component={RouterLink}  >
                                                Volver para afiliarme a otros bancos
                                            </Link>
                                        </Alert>
                                    }



                              

                            </Grid>

                        </Grid>






                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center', paddingTop: '1px !important' }}>
                        <LoadingButton type="submit" variant="contained" onClick={() => { props?.handleClose(props?.type) }} > Aceptar </LoadingButton>
                           {/* <Button onClick={() => { props?.handleCloseCustom(props?.type) }} variant="outlined">Cerrar</Button>*/}

                        </DialogActions>
                   
                </Dialog>
            </ModalStyle>

        </Fragment>









    );
}
