import { Fragment,useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Card, CardHeader, CardContent, Avatar, Typography, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { styled } from '@mui/material/styles';
import logopdf from '../../../assets/images/icons/pdf.png';
import logodoc from '../../../assets/images/icons/formdefault.png';
import { AffiliationLastStepEndCheckIsSaved } from '../../../actions/AffiliationAction';

import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Steps } from '../steps';
import LoadingScreen from '../../../components/LoadingScreen';
import logoBancoPacifico from '../../../assets/images/banks/logo-banco-pacifico.png';
import logoBancoGuayaquil from '../../../assets/images/banks/logo-banco-guayaquil.png';
import logoBancoPichincha from '../../../assets/images/banks/logo-banco-diners-pichincha.png';
import logoBancoSolidario from '../../../assets/images/banks/logo-banco-solidario.png';
import logoBancoBolivariano from '../../../assets/images/banks/logo-banco-bolivariano.png';
import logoBancoProdubanco from '../../../assets/images/banks/logo-banco-produbanco.png';
import logoBancoInternacional from '../../../assets/images/banks/logo-banco-internacional.png';

import DocumentosBancoPacifico from '../../../assets/files/Documentos-afiliacion-banco-del-pacifico.pdf';
import DocumentosBancoGuayaquil from '../../../assets/files/Documentos-Afiliacion-banco-de-guayaquil.pdf';
import DocumentosBancoPichincha from '../../../assets/files/Documentos-afiliacion-banco-pichincha.pdf';
import DocumentosBancoSolidario from '../../../assets/files/Documentos-afiliacion-banco-solidario.pdf';
import DocumentosBancoSolidarioNatural from '../../../assets/files/Documentos-afiliacion-banco-solidario-natural.pdf';
import ConstanciaDocumentosBancoSolidario from '../../../assets/files/Constancia-entrega-documentos-solidario.docx';
import DocumentosBancoBolivariano from '../../../assets/files/Documentos-afiliacion-banco-bolivariano.pdf';
import ConstanciaDocumentosBancoBolivariano from '../../../assets/files/Constancia-entrega-documentos-bolivariano.docx';
import DocumentosBancoProdubanco from '../../../assets/files/Documentos-afiliacion-banco-produbanco.pdf';
import ConstanciaDocumentosBancoProdubanco from '../../../assets/files/Constancia-entrega-documentos-produbanco.docx';
import DocumentosBancoInternacional from '../../../assets/files/Documentos-afiliacion-banco-internacional.pdf';
import { FooterFinal } from "../footer";
import ModalInformation from "../modal/ModalInformation";


const ContentStyle = styled('div')(({ theme }) => ({
 
       

}));


const ContentDonwload = styled('div')(() => ({
    display: 'flex',
    "& img": {
        width: '30px',
        height: '30px'
    },
    "& span": {
        marginLeft: '12px',
        fontSize: '14px'
    }
}));

const CardHeaderCustom = styled('div')(({ theme, width }) => ({
    width: width,
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '19px',

    "& .MuiAvatar-root": {
        /* height: '70px',*/
        width: '180px'
    },

    /* padding:'8px 24px',*/
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }




}));



export default function AdditionalDocumentationContent(props) {

    const { businessData } = useAuth();
    let location = useLocation();
    const [loading, setLoading] = useState(true);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const [openModal, setOpenModal] = useState({
        Information: true
    });

    const handleClose = (type) => {

        setOpenModal(prev => ({
            ...prev,
            [type]: false
        }));

    };

    useEffect(() => {

      

        if (location?.state?.acceptTerms !== undefined && location?.state?.acceptTerms === true) {

            const objForm = {
                UserId: businessData?.userId,
                AffiliationDataId: location?.state?.affiliationDataId
            }

         


            AffiliationLastStepEndCheckIsSaved(objForm).then(response => {

                if (response?.status === 200) {


                    setTermsAccepted(true);


                    setLoading(false);


                } else {



                    setLoading(false);
                   


                }

            });






        } else {

            setLoading(false);
        }

    }, []);












    return (




        <ContentStyle>

            {loading ?

                <Grid container mt={12} mb={12}>
                    <Grid item xs={12} md={12}>
                        <LoadingScreen />
                    </Grid>
                </Grid>

                :
                <Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h4" component="h1" paragraph >
                                Documentación adicional que piden los bancos
                            </Typography>
                            <Typography mb={3}>
                                Descarga el archivo PDF para que tengas a la mano todos los documentos adicionales que solicita cada banco. Algunos documentos  debes entregarlos digital y en físico en la sucursal bancaria indicada. Consulta con tu asesor cualquier duda.
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} mt={1}>

                        <Grid item xs={12} md={12} >
                            <Typography variant="h4" >
                                Bancos Red Datafast
                            </Typography>
                        </Grid>
                      

                        <Grid item xs={12} md={3} >
                            <Card sx={{ zIndex: 'auto' }}>
                                <CardHeaderCustom width="100%">
                                    <CardHeader title={<Avatar alt="banco-solidario-logo" src={logoBancoSolidario} variant="square" />} ></CardHeader>

                                </CardHeaderCustom>

                                <CardContent>

                                    <a href={DocumentosBancoSolidario} download="DocumentacionAdicionalBancoSolidario.pdf" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logopdf} />
                                            <span >Documentos para afiliación Banco Solidario - Persona Juridico </span>
                                        </ContentDonwload>
                                    </a>

                                    <br />

                                    <a href={DocumentosBancoSolidarioNatural} download="DocumentacionAdicionalBancoSolidario.pdf" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logopdf} />
                                            <span >Documentos para afiliación Banco Solidario - Persona Natural </span>
                                        </ContentDonwload>
                                    </a>

                                    <br />

                                    <a href={ConstanciaDocumentosBancoSolidario} download="Constancia-Entrega-Documentos-Solidario.docx" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logodoc} />
                                            <span >Constancia de entrega de documentos </span>
                                        </ContentDonwload>
                                    </a>


                                    <Divider sx={{ marginTop: '15px' }} />

                                    <Accordion style={{ boxShadow: "none" }} >
                                        <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />} sx={{ padding: '0px' }}>
                                            <Typography variant="subtitle1">Indicaciones que debes leer</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: '0px' }}>

                                            <Typography variant="body2" >
                                                Esta documentación adicional que solicita el banco más toda la documentación firmada
                                                y descargada desde la plataforma de afiliación la debes entregar de forma física en la agencia matriz del Banco Solidario.
                                                <br />
                                                <span><b>Dirección Matriz:</b> </span>
                                                <span><b>Contacto:</b ></span>
                                                <br />
                                                No olvides que el banco debe firmar la constancia de entrega. Despues envíasela por email a tu asesor Nuvei.

                                            </Typography>


                                        </AccordionDetails>
                                    </Accordion>


                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} mt={1}>

                        <Grid item xs={12} md={12} >
                            <Typography variant="h4" >
                                Bancos Red Medianet
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={3} >
                            <Card sx={{ zIndex: 'auto' }}>
                                <CardHeaderCustom width="100%">
                                    <CardHeader title={<Avatar alt="banco-pacifico-logo" src={logoBancoBolivariano} variant="square" />} ></CardHeader>

                                </CardHeaderCustom>

                                <CardContent>

                                    <a href={DocumentosBancoBolivariano} download="DocumentacionAdicionalBancoBolivariano.pdf" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logopdf} />
                                            <span >Documentos para afiliación Banco Bolivariano </span>
                                        </ContentDonwload>
                                    </a>
                                    <br />
                                    <a href={ConstanciaDocumentosBancoBolivariano} download="ConstanciaEntregaDocumentos.docx" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logodoc} />
                                            <span >Constancia de entrega de documentación</span>
                                        </ContentDonwload>
                                    </a>


                                    <Divider sx={{ marginTop: '15px' }} />

                                    <Accordion style={{ boxShadow: "none" }} >
                                        <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />} sx={{ padding: '0px' }}>
                                            <Typography variant="subtitle1">Indicaciones que debes leer</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: '0px' }}>

                                            <Typography variant="body2" >
                                                Esta documentación adicional que solicita el banco más toda la documentación firmada y descargada desde la plataforma de afiliación
                                                la debes entregar de forma física en la agencia matriz.
                                                <br /><br />
                                                <span><b>Guayaquil</b>  Agencia matriz Junin 200 y Panamá</span> <br />
                                                <span><b>Otras ciudades:</b > Agencia de su localidad.</span> <br />
                                                <span><b>Contacto:</b > Sobre dirigido a Norma Nuñez Haro y/o José Mackliff.</span> <br />
                                                <br />
                                                No olvides que el banco debe firmar la constancia de entrega. Despues envíasela por email a tu asesor Nuvei.

                                            </Typography>


                                        </AccordionDetails>
                                    </Accordion>


                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={3} >
                            <Card sx={{ zIndex: 'auto' }}>
                                <CardHeaderCustom width="100%">
                                    <CardHeader title={<Avatar alt="banco-pacifico-logo" src={logoBancoProdubanco} variant="square" />} ></CardHeader>

                                </CardHeaderCustom>

                                <CardContent>

                                    <a href={DocumentosBancoProdubanco} download="DocumentacionAdicionalBancoProdubanco.pdf" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logopdf} />
                                            <span >Documentos para afiliación Banco Produbanco</span>
                                        </ContentDonwload>
                                    </a>
                                    <br />
                                    <a href={ConstanciaDocumentosBancoProdubanco} download="ConstanciaEntregaDocumentos.docx" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logodoc} />
                                            <span >Constancia de entrega de documentación</span>
                                        </ContentDonwload>
                                    </a>


                                    <Divider sx={{ marginTop: '15px' }} />

                                    <Accordion style={{ boxShadow: "none" }} >
                                        <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />} sx={{ padding: '0px' }}>
                                            <Typography variant="subtitle1">Indicaciones que debes leer</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: '0px' }}>

                                            <Typography variant="body2" >
                                                Esta documentación adicional que solicita el banco más toda la documentación firmada y descargada desde la plataforma de afiliación
                                                la debes entregar de forma física en la agencia matriz.
                                                <br /><br />
                                                <span><b>Costa:</b>  Parque Empresarial Colón, Torre Produbanco, Av. Rodrigo Chávez</span> <br />
                                                <span><b>Sierra - Cuenca:</b > Vergel en la Roberto Crespo Toral y Av. Del estadio al frente del supermercado Patricia.</span> <br />
                                                <span><b>Contacto:</b > Indicar en recepción el deseo de afiliar tu comercio al banco</span> <br />
                                                <br />
                                                No olvides que el banco debe firmar la constancia de entrega. Despues envíasela por email a tu asesor Nuvei.

                                            </Typography>


                                        </AccordionDetails>
                                    </Accordion>


                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={3} >
                            <Card sx={{ zIndex: 'auto' }}>
                                <CardHeaderCustom width="100%">
                                    <CardHeader title={<Avatar alt="banco-internacional-logo" src={logoBancoInternacional} variant="square" />} ></CardHeader>

                                </CardHeaderCustom>

                                <CardContent>

                                    <a href={DocumentosBancoInternacional} download="DocumentacionAdicionalBancoInternacional.pdf" style={{ textDecoration: 'none', color: '#637381' }} >
                                        <ContentDonwload >
                                            <img src={logopdf} />
                                            <span >Documentos para afiliación Banco Produbanco</span>
                                        </ContentDonwload>
                                    </a>
                                    <br />



                                    <Divider sx={{ marginTop: '15px' }} />

                                    <Accordion style={{ boxShadow: "none" }} >
                                        <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />} sx={{ padding: '0px' }}>
                                            <Typography variant="subtitle1">Indicaciones que debes leer</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: '0px' }}>

                                            <Typography variant="body2" >
                                                Esta documentación adicional que solicita el banco la debes enviar por email a tu asesor Nuvei.
                                                <br /> <br />
                                                Estos serán adjuntados a toda la documentación que llenaste en la plataforma.
                                                <br /> No demores en enviárselos

                                            </Typography>


                                        </AccordionDetails>
                                    </Accordion>


                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>


                    {(location?.state?.acceptTerms !== undefined && location?.state?.acceptTerms === true && termsAccepted === false) &&




                        <>
                        <FooterFinal
                            size1={10}
                            size2={2}
                            affiliationId={location?.state?.affiliationDataId}
                            showConditions={true}
                            conditionsText="Acepto que he descargado y leído el PDF de documentos adicionales que piden los bancos.
"
                            textButton2="Continuar"

                        />



                        {/* Modal Information */}
                        {openModal?.Information &&
                            <ModalInformation handleClose={handleClose} type="Information" finalStep={true} />
                        }


                        </>

                    }





                </Fragment>
            }
        </ContentStyle>


    );
}
