import HttpClient from '../services/HttpClient';
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;



export const SaveAffiliationData = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationDataSave", affiliation).then(response => {
            resolve(response);
        }).catch(error => {
            //// Acceder a detalles del error
            //const status = error.response.status; // C�digo de estado HTTP
            //const data = error.response.data; // Datos de la respuesta
            //const headers = error.response.headers; // Encabezados de la respuesta
            //console.log("status", status);
            //console.log("data", data);
            //console.log("headers", headers);
            resolve(error.response);
        });
    });
}




export const AffiliationSaveReviewData = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationSaveReviewData", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationNew = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationNew", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const GetAffiliationData = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationDataGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationDataGetListPagination = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationDataGetListPagination", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationDataCollectGetListPagination = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDataCollectGetListPagination", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const GetBusinessData = async (business) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/business/businessDataGet", business).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}





export const GetAllAffiliation = async (business) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationDataGetList", business).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const GetAffiliationSteps = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationStepsGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}







export const AffiliationStatusDetailGet = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationStatusDetailGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationStatusEstablishmentDataDetailGet = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationStatusEstablismentDataDetailGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationStatuEnablingDocumentsDetailGet = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationStatuEnablingDocumentsDetailGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationStatuPaymentezDetailGet = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationStatuPaymentezDetailGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationStatuBanksDetailGet = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationStatuBanksDetailGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}







export const AffiliationGetPmzForms = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationGetPmzForms", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetpercentagePmz = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationGetPmzPercentage", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}






export const AffiliationGetPercentageDocuments = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationGetPercentageDocuments", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const AffiliationLastStepEnd = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationLastStepEnd", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationLastStepEndCheckIsSaved = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationLastStepEndCheckIsSaved", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}







export const GetBankList = async () => {

    return new Promise((resolve, eject) => {

        HttpClient.get("/bank/banklist").then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}





export const AffiliationHasBanksAdded = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationHasBanksAdded", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationBanksGet = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationBanksGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationBanksGetAllActive = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationBanksGetAllActive", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const SaveAddBankAffiliation = async (objBank) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationBanksAdd", objBank).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}


export const AffiliationBanksRemove = async (objBank) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationBanksRemove", objBank).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const GetFormsByBank = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationbankGetByBankId", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const SaveLinkToPay = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationSaveLinkToPay", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const SaveLinkPaymentGateway = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationSaveLinkPaymentGateway", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const AffiliationGetDocumentsSign = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationGetDocumentsSign", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationDataPaymentezAndBanksAllActiveDocumentation = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDataPaymentezAndBanksAllActiveDocumentation", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const AffiliationGetDocumentsSignByBank = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationGetDocumentsSignByBank", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationDownloadBanksPreview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post(objAffiliation.EndPoint, objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const AffiliationDownloadBankFileToSign = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post(objAffiliation.EndPoint, objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationFileBankSaveOnAmazon = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post(objAffiliation.endPoint, objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const AffiliationDownloadPmzFileToSign = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post(objAffiliation.EndPoint, objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationFilePmzSaveOnAmazon = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post(objAffiliation.endPoint, objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationTestAmazonFile = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationTestAmazonFile", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationBanksGetAllDocumentation = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationBanksGetAllDocumentation", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const AffiliationBanksGetAllDocumentationSign = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationBanksGetAllDocumentationSign", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationDownloadAllDocumentation = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDownloadAllDocumentation", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}






export const AffiliationEnableOnlySignatures = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationEnableOnlySignatures", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationEnableOnlyForms= async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationEnableOnlyForms", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationEnableBanks = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationEnableBanks", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}






export const EndAffiliation = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/EndAffiliation", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationDataGetByAdvisor = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDataGetByAdvisor", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationDataGetHeaderByArea = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDataGetHeaderByArea", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const AffiliationReviewByBusinessToAdvisor = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationReviewByBusinessToAdvisor", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const AffiliationCorrectionForms = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationCorrectionForms", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationSetDateDocuments = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationSetDateDocuments", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationDateDocumentGet = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDateDocumentGet" , objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationTempValuesModalsGet = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationTempValuesModalsGet", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const AffiliationSaveAcquiring = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationSaveAcquiring", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



export const AffiliationHasCreditAdded = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationHasCreditAdded", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetCardsListAndDataAffiliation = async (affiliation) => {

    const params = Object.entries(affiliation)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    const url = `/affiliation/GetCardsListAndDataAffiliation?${params}`;

    return new Promise((resolve, eject) => {

        HttpClient.get(url).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}




//Formularios banco pacifico


export const GetBdPAnexo2 = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo2Get", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPAnexo2Save = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo2Save", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetBdPAnexo2Preview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo2GetPReview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetBdPAnexo1 = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo1Get", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPAnexo1Save = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo1Save", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetBdPAnexo1Preview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo1GetPreview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}





export const GetBdPCartaAdquirencia = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPCartaAdquirenciaGet", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPCartaAdquirenciaSave = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPCartaAdquirenciaSave", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetBdPCartaAdquirenciaPreview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPCartaAdquirenciaGetPreview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}






export const GetBdPAdendumContratoEstablecimiento = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAdendumContratoEstablecimientoGet", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPAdendumContratoEstablecimientoSave = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAdendumContratoEstablecimientoSave", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetBdPAdendumContratoEstablecimientoPreview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAdendumContratoEstablecimientoGetPreview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const BdPContratoAfiliacionGet = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPContratoAfiliacionGet", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPContratoAfiliacionSave = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPContratoAfiliacionSave", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const BdPContratoAfiliacionGetPreview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPContratoAfiliacionGetPreview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const BdPAnexo4Save = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo4Save", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPAnexo4Get = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo4Get", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const BdPAnexo4GetPreview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAnexo4GetPreview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const BdPInspeccionFisicaSave = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPInspeccionFisicaSave", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPInspeccionFisicaGet = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPInspeccionFisicaGet", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const BdPInspeccionFisicaGetPreview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPInspeccionFisicaGetPreview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const BdPAdendumTransferenciaSave = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAdendumTransferenciaSave", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}
export const BdPAdendumTransferenciaGet = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAdendumTransferenciaGet", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const BdPAdendumTransferenciaGetPreview = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/BcoPacificoForms/BdPAdendumTransferenciaGetPreview", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}



//Agregador
export const SaveAffiliationCollectData = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationCollectDataSave", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetAffiliationDataCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDataGetCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationCollectFirstStepEnd = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationCollectFirstStepEnd", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationSaveReviewCollectData = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationSaveReviewCollectData", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetBusinessCollectData = async (business) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/business/businessDataCollectGet", business).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationStatusDetailCollectGet = async (business) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationStatusDetailCollectGet", business).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationStatuEnablingDocumentsDetailCollectGet = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationStatuEnablingDocumentsDetailCollectGet", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationReviewByBusinessCollectToAdvisor = async (objAffiliation) => {

    return new Promise((resolve, reject) => {

        HttpClient.post("/affiliation/AffiliationReviewByBusinessCollectToAdvisor", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationCollectEdit = async (objAffiliation) => {

    return new Promise((resolve, reject) => {

        HttpClient.post("/affiliation/AffiliationCollectEdit", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const GetAffiliationStepsCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationStepsGetCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}




export const AffiliationCardsAdd = async (objBank) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationCardsAdd", objBank).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });

}

export const AffiliationGetNuveiFormsCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationGetNuveiFormsCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const GetpercentageNuveiCollect = async (affiliation) => {
    return new Promise((resolve, eject) => {
        HttpClient.post("/affiliation/affiliationGetpercentageNuveiCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}


export const AffiliationLastStepEndCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationLastStepEndCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const affiliationGetPercentageDocumentsCollect = async (affiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/affiliationGetPercentageDocumentsCollect", affiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationGetDocumentsSignCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationGetDocumentsSignCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const EndAffiliationCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/EndAffiliationCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationNuveiGetAllDocumentationCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationNuveiGetAllDocumentationCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationDownloadAllDocumentationCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDownloadAllDocumentationCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

export const AffiliationDownloadAllDocumentationSignCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDownloadAllDocumentationSignCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}


export const AffiliationEnableOnlySignaturesCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationEnableOnlySignaturesCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            eject(error.response);
        });
    });
}

export const AffiliationDataNuveiDocumentation = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationDataNuveiDocumentation", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            eject(error.response);
        });
    });
}


export const AffiliationCorrectionFormsCollect = async (objAffiliation) => {

    return new Promise((resolve, eject) => {

        HttpClient.post("/affiliation/AffiliationCorrectionFormsCollect", objAffiliation).then(response => {
            resolve(response);
        }).catch(error => {

            resolve(error.response);
        });
    });
}

