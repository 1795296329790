import { useState, useEffect } from "react";
import { Stepper, Step, StepLabel} from '@mui/material';
import { useParams} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { styled } from '@mui/material/styles';


const ContentStyle = styled('div')(({ theme }) => ({
    
}));


export default function Steps() {

    const { businessData} = useAuth();
    const params = useParams();
    const [steps, setSteps] = useState({
        establishmentData: 0,
        enablingDocuments: 0,
        paymentezDocuments: 0,
        banksDocuments:0,
    });


    const InitializedSteps = () => {
        

        const establishmentData = businessData?.affiliationData?.find(e => e.affiliationDataId === params.affiliation)?.stepStatus?.find(e => e.step === 1).status;
        const enablingDocuments = businessData?.affiliationData?.find(e => e.affiliationDataId === params.affiliation)?.stepStatus?.find(e => e.step === 2).status;
        const paymentezDocuments = businessData?.affiliationData?.find(e => e.affiliationDataId === params.affiliation)?.stepStatus?.find(e => e.step === 3).status;
        const banksDocuments = businessData?.affiliationData?.find(e => e.affiliationDataId === params.affiliation)?.stepStatus?.find(e => e.step === 4).status;



        setSteps(prev => ({
            ...prev,
            establishmentData: establishmentData !== undefined ? establishmentData : 0,
            enablingDocuments: enablingDocuments !== undefined ? enablingDocuments : 0,
            paymentezDocuments: paymentezDocuments !== undefined ? paymentezDocuments : 0,
            banksDocuments: banksDocuments !== undefined ? banksDocuments : 0
        }));

    }
 
    useEffect(() => {

     
        InitializedSteps();

    }, [businessData?.affiliationData]);




    return (

        <ContentStyle>



           
            <Stepper alternativeLabel >
                <Step active={(steps?.establishmentData === 0 || steps?.establishmentData === 2)} completed={(steps?.establishmentData === 1 || steps?.establishmentData === 3 || steps?.establishmentData === 5 || steps?.establishmentData === 7) ? true : false}>
                    <StepLabel className={(steps?.establishmentData === 0 || steps?.establishmentData === 2) ? "step-warning" : ""}>
                        Datos del establecimiento
                    </StepLabel>
                </Step>
                <Step active={(steps?.enablingDocuments === 0 || steps?.enablingDocuments === 2) ? false : true} completed={(steps?.enablingDocuments === 1 || steps?.enablingDocuments === 3 || steps?.enablingDocuments === 5 || steps?.enablingDocuments === 7) ? true : false}>
                    <StepLabel className={(steps?.enablingDocuments === 0 || steps?.enablingDocuments === 2)? "step-warning": ""}>
                        Documentos habilitantes
                    </StepLabel>
                </Step>
                <Step active={(steps?.paymentezDocuments === 0 || steps?.paymentezDocuments === 2) ? false : true} completed={(steps?.paymentezDocuments === 1 || steps?.paymentezDocuments === 3 || steps?.paymentezDocuments === 5 || steps?.paymentezDocuments === 7) ? true : false}>
                    <StepLabel className={(steps?.paymentezDocuments === 0 || steps?.paymentezDocuments === 2) ? "step-warning" : ""}>
                        Documentación Nuvei
                    </StepLabel>
                </Step>
                <Step active={(steps?.banksDocuments === 0 || steps?.banksDocuments === 2) ? false : true} completed={(steps?.banksDocuments === 1 || steps?.banksDocuments === 3 || steps?.banksDocuments === 5 || steps?.banksDocuments === 7) ? true : false}>
                    <StepLabel className={(steps?.banksDocuments === 0 || steps?.banksDocuments === 2) ? "step-warning" : ""}>
                        Documentación Bancos
                    </StepLabel>
                </Step>
            </Stepper>

        </ContentStyle>




                
           
         
   

    );
}
