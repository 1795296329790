import { forwardRef } from "react";
import { useSnackbar } from 'notistack';
import { MIconButton } from "../components/@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";
import { Icon } from '@iconify/react';
import { PDFDocument } from 'pdf-lib';
import { format } from 'date-fns';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import NumberFormat from 'react-number-format';



//const DateIsReal = (date) => {

//    if (date === null || date === undefined || date === "0001-01-01T00:00:00") {
//        return false;
//    }

//    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
//    const jsDefaultDatePattern = /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \(hora de (.+)?\)$/;
//    const jsDefaultDatePatternEn = /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \(time zone (.+)?\)$/;

//    if (isoDatePattern.test(date) || jsDefaultDatePattern.test(date) || jsDefaultDatePatternEn.test(date)) {

//        const dateToCheck = new Date(date);
//        return Object.prototype.toString.call(dateToCheck) === "[object Date]" && !isNaN(dateToCheck);
//    }

//    return false;
//};

const DateIsReal = (date) => {
    // Verifica si la fecha es null, undefined o la fecha mínima (0001-01-01T00:00:00)
    if (date === null || date === undefined || date === "0001-01-01T00:00:00") {
        return false;
    }

    // Intenta crear un objeto Date a partir de la cadena proporcionada
    const parsedDate = new Date(date);

    // Verifica si el objeto Date es válido y no es NaN
    return !isNaN(parsedDate) && parsedDate instanceof Date;
};




const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
};

const readFile = (file) => {

    return new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

        reader.readAsArrayBuffer(file);
    });
}


const NumberPagesPdf = async (fileInfo) => {
    const arrayBuffer = await readFile(fileInfo);
    const pdf = await PDFDocument.load(arrayBuffer);
    return pdf.getPages()?.length;
}


const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
        cb(null, reader.result)
    }
    reader.onerror = function (error) {
        cb(error, null)
    }
}

const ChangeFieldBoolToString = (value) => {
   
    if (value === null || value === "") {
        return "";
    }
    if (value === true) { return "true"; }
    if (value === false) { return "false"; }
};

const ChangeFieldIntToString = (value) => {
    if (value === null || value === "") {
        return "";
    }
    return value?.toString();
};


const ChangeFieldStringToBool = (value) => {
    if (value === null || value === undefined) {
        return null;
    }
    if (value === "true") { return true; }
    if (value === "false") { return false; }
};

const ChangeFieldStringToInt = (value) => {
    if (value === null || value === "") {
        return "";
    }
    return parseInt(value);
};

const DateConvert = (dateAffiliation) => {
    if (dateAffiliation === undefined || dateAffiliation === null) { return ""; }
    const date = new Date(dateAffiliation);
    return format(date, "dd/MM/yyyy");
}


const CommentsContent = (value) => {

    if (value === undefined || value === null || value === "") {
        return "";
    }

    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    return editorState;
};


const ListTypeRiskyBusiness = [
    { value: '', label: '' },
    { value: '5967', label: 'Mercadeo Directo - Comercios de Telemercadeo Entrante' },
    { value: '5966', label: 'Mercadeo Directo—Comercios de Telemercadeo Saliente' },
    { value: '7841', label: 'Tiendas de  Alquiler de Videos de Entretenimiento' },
    { value: '7273', label: 'Servicios de citas' },
    { value: '7801', label: 'Juegos de Azar por Internet' },
    { value: '7802', label: 'Carreras de Caballos/Galgos con Licencia del Gobierno' },
    { value: '7995', label: 'Transacciones de Juegos de Azar' },
    { value: '5122', label: 'Medicamentos, Propietarios de Medicamentos Patentados y Artículos Diversos de Farmacia' },
    { value: '5912', label: 'Droguerías, Farmacias' },
    { value: '5993', label: 'Tiendas y Expendedores de Tabaco' },
    { value: '4816', label: 'Red de Computadoras/Servicios de Información' },
    { value: '6211', label: 'Títulos Valores—Corredores/Vendedores' },
    { value: '6051', label: 'Cuasi Efectivo—Comercio' },
    { value: '5816', label: 'Productos Digitales—Juegos' },
    { value: '5962', label: 'Mercadeo Directo—Servicios Relacionados con Coordinación de Viajes' },
]



const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            isNumericString

        />
    );
});



export const useUtils = () => {
    

  
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  

    const ErrorNotification = (text) => {
        enqueueSnackbar(text, {
            variant: 'error',
            action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });
    };

    const CustomNotification = (text, variant = 'success', autoHideDuration = 2000,) => {
        enqueueSnackbar(text, {
            autoHideDuration: autoHideDuration,
            variant: variant,
            action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });
    };



    return {
        NumberPagesPdf,
        //metodos
        ErrorNotification,
        CustomNotification,
        readFile,
        fileToBase64,
        onlyNumbers,
        ChangeFieldBoolToString,
        ChangeFieldIntToString,
        ChangeFieldStringToBool,
        ChangeFieldStringToInt,
        DateConvert,
        CommentsContent,
        ListTypeRiskyBusiness,
        NumberFormatCustom,
        DateIsReal

    };

};